:root {
  --primary-color: #F9F5E9;
  --secondary-color: lightcoral;
}

body {
  background: var(--primary-color);
}

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.imgAreaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.imgContainer { 
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: 20px 0;
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

img {
  width: 100%;
}

button { 
  margin: 2px auto;
  max-width: 360px;
  min-width: 200px;
  background: transparent;
  border: 1px solid var(--secondary-color);
  padding: 0;
  text-decoration: none;
}

button:hover {
  background-color: var(--secondary-color);
  transition-duration: 250ms;
}

button:hover a {
  color: var(--primary-color);
  transition-duration: 250ms; 
}

a {
  text-decoration: none; 
  display: block;
  padding: 12px;
  color: var(--secondary-color);
}

.buttonsList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.footerText {
  width: 100%;
  bottom: 0px;
  text-align: center;
  margin: 40px 0 20px 0;
  font-size: 9px;
}
